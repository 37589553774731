<template>
  <div class="page-container">
    <img class="bg-download" :src="downloadImg" alt="app下载" />
    <img class="qr-code" :src="qrCode" alt="app下载" />
  </div>
</template>
<script>
import downloadBg from "@/assets/img/download/download-bg.png";
import qrCode from "@/assets/img/download/qrCode.png";

export default {
  data() {
    return {
      downloadImg: downloadBg,
      qrCode: qrCode,
    };
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.page-container {
  overflow: hidden;
  position: relative;
  .bg-download {
    width: 100%;
    float: left;
  }
  .qr-code {
    position: absolute;
    left: 50.83%;
    top: 33.4%;
    width: 7.71%;
  }
}

#footer {
  margin-top: 0px;
}
</style>
